import React from "react";
import logoSrc from "../PhishInstinct_Logo_black.png";

const Phished_TTL = () => {
  return (
    <div className="flex flex-col justify-center items-center min-h-screen bg-gray-100 px-4">
      {/* Logo Section */}
      <img
        className="w-1/3 mb-6 animate-bounce"
        src={logoSrc}
        alt="Logo"
      />
      
      {/* Animated Oops Message */}
      <h1 className="text-4xl lg:text-5xl font-bold text-red-600 animate-pulse">
        Oops! You have been phished!
      </h1>

      {/* Subtext */}
      <p className="mt-6 text-center text-lg text-gray-700 leading-relaxed">
        This was a <strong className="text-black">Phishing Test</strong> conducted by the <br />
        <span className="text-indigo-600 font-semibold">InfoSec Team</span>.
      </p>

      {/* Call to Action */}
      <p className="mt-4 text-center text-gray-700">
        Stay vigilant and always verify emails for suspicious links or attachments.
      </p>
    </div>
  );
};

export default Phished_TTL;
